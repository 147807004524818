import { byId, now, sanitizeTranlations, toISOStringWithTimezone } from "@/js/common";
import { useDayjs } from "@/js/providers/DayjsProvider";
import { Category, Model, Video, VideoUpdate } from "@/js/resources";
import { status, Status } from "@/js/types";
import { setFormValues, SubmitHandler } from "@enymo/react-form-component";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getTitle } from "../../common";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import Filter from "../Filter";
import Button from "../form/Button";
import Input from "../form/Input";
import SelectModels from "../form/SelectModels";
import Pagination from "../Pagination";
import SettingsPopup, { Submit } from "../popup/SettingsPopup";
import StatusTag from "../StatusTag";
import Table, { SortBy } from "../Table";
import TableHeader from "../TableHeader";

export type VideosSortBy = SortBy<"title" | "id" | "category" | "publish_at" | "filename">;

export default function Videos({
    videos,
    onUpdateVideo,
    onCreateVideo,
    onDeleteVideo,
    categories,
    models,
    onSearch,
    sortBy,
    onChangeSortBy,
    filterStatus,
    onChangeFilterStatus,
    filterCategory,
    onChangeFilterCategory,
    filterModels,
    onChangeFilterModels,
    ...props
}: {
    videos?: Pick<Video, "id" | "category_id" | "category" | "translations" | "models" | "featured_preview" | "publish_at" | "unpublished_at" | "status" | "filename">[],
    onUpdateVideo: (id: number, data: Partial<VideoUpdate>) => void | Promise<void>;
    onCreateVideo: (data: Omit<VideoUpdate, "rating" | "favorite" | "watch_later" | "view">) => void | Promise<void>,
    onDeleteVideo: (id: number) => void | Promise<void>,
    categories?: Pick<Category, "id" | "title">[],
    models?: Pick<Model, "id" | "first_name" | "last_name" | "description" | "avatar">[],
    page: number,
    itemsPerPage: number,
    totalItems: number,
    onSearch: (search: string) => void,
    onChangePage: (page: number) => void,
    sortBy: VideosSortBy,
    onChangeSortBy: (sortBy: VideosSortBy) => void,
    filterStatus: Status | null,
    onChangeFilterStatus: (filterStatus: Status | null) => void,
    filterCategory: number | null,
    onChangeFilterCategory: (filterCategory: number | null) => void,
    filterModels: number[],
    onChangeFilterModels: (filterModels: number[]) => void
}) {
    const {t} = useTranslation();
    const dayjs = useDayjs();
    const withPopup = usePopup();

    const [editVideoId, setEditVideoId] = useState<number | "create" | null>(null);
    const form = useForm<Submit>();
    const activeFilters = useMemo(() => [filterStatus, filterCategory].reduce<number>((prev, cur) => cur !== null ? prev + 1 : prev, 0) + (filterModels.length > 0 ? 1 : 0), [filterCategory, filterModels, filterStatus]);

    const handleToggleUnpublishVideo = (id: number) => {
        const video = requireNotNull(videos?.find(byId(id)), "unable to find video");
        const title = getTitle(video.translations);
        withPopup(video.unpublished_at !== null ? {
            title: t("videos.publish"),
            text: title ? t("videos.publish.text.title", {title}) : t("videos.publish.text.id", {id}),
            confirm: t("videos.publish"),
            type: "confirm",
            onConfirm: () => onUpdateVideo(id, {unpublished: false})
        } : {
            title: t("videos.unpublish"),
            text: title ? t("videos.unpublish.text.title", {title}) : t("videos.unpublish.text.id", {id}),
            confirm: t("videos.unpublish"),
            type: "confirm",
            onConfirm: () => onUpdateVideo(id, {unpublished: true})
        });
    }

    const handleDeleteVideo = (id: number) => {
        const video = requireNotNull(videos?.find(byId(id)), "unable to find video");
        const title = getTitle(video.translations);
        withPopup({
            title: t("videos.delete"),
            text: title ? t("videos.delete.text.title", {title}) : t("videos.delete.text.id", {id}),
            confirm: t("videos.delete"),
            type: "confirm",
            variant: "danger",
            onConfirm: () => onDeleteVideo(id)
        })
    }

    const handleEditVideo = (id: number) => {
        const video = requireNotNull(videos?.find(byId(id)), "unable to find video");
        setEditVideoId(id);
        setFormValues(form, {
            category_id: video.category_id,
            translations: video.translations,
            models: video.models?.map(({id}) => id),
            publish_at: toISOStringWithTimezone(video.publish_at, true) as any
        })
    }

    const handleSubmit: SubmitHandler<Submit> = async data => {
        assertNotNull(editVideoId);
        const sanitized = {
            ...data,
            translations: sanitizeTranlations(data.translations)
        }
        await (editVideoId === "create" ? onCreateVideo({
            ...sanitized,
            unpublished: false
        }) : onUpdateVideo(editVideoId, sanitized));
        setEditVideoId(null);
    }

    return <>
        <Breadcrumbs breadcrumbs={[{children: t("videos")}]} />
        <div className="flex-1 flex flex-col overflow-y-auto">
            <TableHeader onSearch={onSearch} filter={
                <Filter active={activeFilters}>
                    <Input type="select" label={t("status")} value={filterStatus ?? ""} onChange={value => onChangeFilterStatus(value || null)} choices={[{
                        label: t("filters.all"),
                        value: ""
                    }, ...status.map(status => ({
                        label: t(`status.${status}`),
                        value: status
                    }))]} />
                    <Input type="select" label={t("category")} value={filterCategory?.toString() ?? ""} onChange={value => onChangeFilterCategory(value ? Number(value) : null)} choices={[{
                        label: t("filters.all"),
                        value: ""
                    }, ...categories?.map(({id, title}) => ({
                        label: title,
                        value: id.toString()
                    })) ?? []]} />
                    <SelectModels label={t("models")} placeholder={t("filters.all")} models={models ?? []} value={filterModels} onChange={onChangeFilterModels} />
                </Filter>
            }>
                <Button variant="primary" onClick={() => setEditVideoId("create")}>{t("videos.add")}</Button>
            </TableHeader>
            <Pagination borderBottom {...props} />
            <div className="overflow-x-auto flex-1">
                {videos && (
                    <Table
                        sortBy={sortBy}
                        onChangeSortBy={onChangeSortBy}
                        head={[{
                            label: t("videos.preview")
                        }, {
                            name: "title",
                            label: t("videos.title")
                        }, {
                            name: "id",
                            label: t("id")
                        }, {
                            name: "category",
                            label: t("videos.category")
                        }, {
                            label: t("status")
                        }, {
                            label: t("settings.models")
                        }, {
                            name: "publish_at",
                            label: t("settings.publishAt")
                        }, {
                            name: "filename",
                            label: t("filename")
                        }, {
                            fill: true
                        }]}
                        rows={videos.map(({translations, id, category, models, publish_at, unpublished_at, featured_preview, status, filename}) => {
                            assertNotNull(category, "category error");
                            assertNotNull(models, "models error");
                            return {
                                id,
                                data: [{
                                    children: featured_preview ? (
                                        <img {...featured_preview.thumbnail} id={undefined} className="aspect-video h-12 w-auto max-w-none object-cover" />
                                    ) : (
                                        <div className="aspect-video h-12 bg-neutral-600" />
                                    )
                                }, {
                                    className: "body-m-md",
                                    children: getTitle(translations) || "-"
                                }, {
                                    children: id
                                }, {
                                    children: category.title
                                }, {
                                    children: (
                                        <div className="flex">
                                            {models.length === 0 ? (
                                                <StatusTag variant="warning">{t("status.missingModels")}</StatusTag>
                                            ) : status === "incomplete" ? (
                                                <StatusTag variant="neutral">{t("status.incomplete")}</StatusTag>
                                            ) : status === "processing" ? (
                                                <StatusTag variant="neutral">{t("status.processing")}</StatusTag>
                                            ) : status === "error" ? (
                                                <StatusTag variant="error">{t("status.error")}</StatusTag>
                                            ) : unpublished_at !== null ? (
                                                <StatusTag variant="warning">{t("status.unpublished")}</StatusTag>
                                            ) : publish_at! > now ? (
                                                <StatusTag variant="neutral">{t("status.planned")}</StatusTag>
                                            ) : (
                                                <StatusTag variant="success">{t("status.published")}</StatusTag>
                                            )}
                                        </div>
                                    )
                                }, {
                                    children: models.map(({first_name}) => first_name).join(", ")
                                }, {
                                    children: dayjs(publish_at).format("L")
                                }, {
                                    children: filename
                                }, {
                                    children: (
                                        <div className="flex justify-end">
                                            <DotsDropdown items={[{
                                                onClick: () => handleEditVideo(id),
                                                children: t("videos.settings")
                                            }, {
                                                to: `/videos/${id}/video`,
                                                children: t("videos.editVideo")
                                            }, {
                                                to: `/videos/${id}/previews`,
                                                children: t("videos.editPreviews")
                                            }, {
                                                to: `${import.meta.env.VITE_APP_URL}/videos/${id}`,
                                                linkType: "new-tab",
                                                children: t("videos.openPage")
                                            }, {
                                                onClick: () => handleToggleUnpublishVideo(id),
                                                children: unpublished_at !== null ? t("videos.publish") : t("videos.unpublish")
                                            }, {
                                                onClick: () => handleDeleteVideo(id),
                                                variant: "danger",
                                                children: t("videos.delete")
                                            }]} />
                                        </div>
                                    )
                                }]
                            }
                        })}
                    />
                )}
            </div>
        </div>
        <Pagination {...props} />
        {categories && models && editVideoId !== null && (
            <SettingsPopup
                title={editVideoId === "create" ? t("videos.create") : t("videos.settings")}
                form={form}
                onSubmit={handleSubmit}
                onCancel={() => setEditVideoId(null)}
                categories={categories}
                models={models}
            />
        )}
    </>
}